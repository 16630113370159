import { CookieCategory, CookieConsentState } from '../hooks/useCookieConsent';

interface CookieOptions {
    path?: string;
    domain?: string;
    maxAge?: number;
    expires?: Date | string;
    secure?: boolean;
    sameSite?: 'strict' | 'lax' | 'none';
}

interface CookieConfig {
    name: string;
    category: CookieCategory;
    description?: string;
    expiry?: string;
    provider?: string;
}

// Register cookie configurations here
export const cookieConfigs: CookieConfig[] = [
    {
        name: 'cookie-consent',
        category: 'necessary',
        description: 'Stores your cookie consent preferences',
        expiry: '1 year',
        provider: 'Own website'
    },
];

export class CookieManager {
    private static instance: CookieManager;

    private constructor() { }

    public static getInstance(): CookieManager {
        if (!CookieManager.instance) {
            CookieManager.instance = new CookieManager();
        }
        return CookieManager.instance;
    }

    public setCookie(name: string, value: string, options: CookieOptions = {}) {
        const { path = '/', domain, maxAge, expires, secure, sameSite } = options;
        let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; path=${path}`;

        if (domain) {
            cookieString += `; domain=${domain}`;
        }

        if (maxAge) {
            cookieString += `; max-age=${maxAge}`;
        }

        if (expires) {
            const expiresDate = typeof expires === 'string' ? new Date(expires) : expires;
            cookieString += `; expires=${expiresDate.toUTCString()}`;
        }

        if (sameSite) {
            cookieString += `; samesite=${sameSite}`;
        }

        cookieString += `; secure`; // Add secure attribute to ensure cookies are only sent over HTTPS

        document.cookie = cookieString;
    }

    public getCookie(name: string): string | null {
        const nameEQ = `${encodeURIComponent(name)}=`;
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
            }
            if (cookie.indexOf(nameEQ) === 0) {
                return decodeURIComponent(cookie.substring(nameEQ.length, cookie.length));
            }
        }
        return null;
    }

    public deleteCookie(name: string, path: string = '/'): void {
        document.cookie = `${encodeURIComponent(name)}=; path=${path}; max-age=-1; Secure`;
    }
}