import { useState, useEffect } from 'react';
import { CookieManager } from '../utils/cookieManager';

export type CookieCategory = 'necessary' | 'statistics' | 'marketing' | 'preferences';

export interface CookieConsentState {
    necessary: boolean;
    preferences: boolean;
    // statistics: boolean;
    marketing: boolean;
    hasResponded: boolean;
}

const COOKIE_CONSENT_KEY = 'cookie-consent';
const COOKIE_CONSENT_VERSION = '1.0'; // Increment when making changes to consent structure

export interface UseCookieConsentReturnType {
    cookieConsent: CookieConsentState;
    updateCookieConsent: (categories: Partial<CookieConsentState>, hideBanner?: boolean) => void;
    resetCookieConsent: () => void;
    isBannerVisible: boolean;
    setBannerVisible: (visible: boolean) => void;
}

const defaultConsent: CookieConsentState = {
    necessary: true, // necessary cookies are always allowed
    preferences: true,
    // statistics: true,
    marketing: true,
    hasResponded: false,
};

export const useCookieConsent = (): UseCookieConsentReturnType => {
    const [cookieConsent, setCookieConsent] = useState<CookieConsentState>(defaultConsent);
    const [isBannerVisible, setBannerVisible] = useState<boolean>(false);

    useEffect(() => {
        const loadSavedPreferences = () => {
            try {
                const savedPreferences = CookieManager.getInstance().getCookie(COOKIE_CONSENT_KEY);
                if (savedPreferences) {
                    const parsed = JSON.parse(savedPreferences);

                    if (parsed.version !== COOKIE_CONSENT_VERSION) {
                        return resetCookieConsent()
                    }

                    setCookieConsent({
                        necessary: true, // Always true
                        preferences: !!parsed.preferences,
                        // statistics: !!parsed.statistics,
                        marketing: !!parsed.marketing,
                        hasResponded: !!parsed.hasResponded,
                    })

                    // Show the banner if the user has not responded
                    if (!parsed.hasResponded) {
                        setBannerVisible(true);
                    }
                } else {
                    // No saved preferences found, reset consent and show banner
                    resetCookieConsent();
                }
            } catch (error) {
                console.error('Error loading cookie preferences:', error);
            }
        };

        if (typeof window !== "undefined") {
            loadSavedPreferences()
        }
    }, [])

    const savePreferences = (newPreferences: CookieConsentState) => {
        try {
            CookieManager.getInstance().setCookie(COOKIE_CONSENT_KEY, JSON.stringify({
                ...newPreferences,
                version: COOKIE_CONSENT_VERSION,
            }), { path: '/', maxAge: 365 * 24 * 60 * 60 });
            setCookieConsent(newPreferences);
        } catch (error) {
            console.error('Error saving cookie preferences:', error);
        }
    };

    const updateCookieConsent = (categories: Partial<CookieConsentState>, hideBanner: boolean = false) => {
        const newPreferences = {
            ...cookieConsent,
            ...categories,
            hasResponded: hideBanner ? true : cookieConsent.hasResponded,
        };
        savePreferences(newPreferences);
        if (hideBanner) {
            setBannerVisible(false);
        }
    };

    const resetCookieConsent = () => {
        CookieManager.getInstance().deleteCookie(COOKIE_CONSENT_KEY);
        setCookieConsent(defaultConsent);
        setBannerVisible(true);
    };

    return {
        cookieConsent,
        updateCookieConsent,
        resetCookieConsent,
        isBannerVisible,
        setBannerVisible,
    };
};