import React, { createContext, useContext, useEffect } from 'react'
import { useCookieConsent, UseCookieConsentReturnType } from '../hooks/useCookieConsent'
import { CookieManager } from '../utils/cookieManager'

const CookieConsentContext = createContext<UseCookieConsentReturnType | undefined>(undefined)

interface CookieConsentProviderProps {
    children: React.ReactNode
}

export const CookieConsentProvider: React.FC<CookieConsentProviderProps> = ({ children }) => {
    const cookieConsentValues = useCookieConsent()

    useEffect(() => {
        const injectScript = (scriptContent: string, id: string) => {
            if (!document.getElementById(id)) {
                const script = document.createElement('script');
                script.id = id;
                script.async = true;
                script.textContent = scriptContent;
                document.head.appendChild(script);
            }
        }

        const savedPreferences = CookieManager.getInstance().getCookie('cookie-consent')

        if (!savedPreferences) return
        const parsedCookies = JSON.parse(savedPreferences)

        if (parsedCookies.marketing) {
            injectScript(
                `
                    _linkedin_partner_id = "289036";
                    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                `, 'marketing-script'
            )

            injectScript(
                `
                   (function(l) {
                    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                    window.lintrk.q=[]}
                    var s = document.getElementsByTagName("script")[0];
                    var b = document.createElement("script");
                    b.type = "text/javascript";b.async = true;
                    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                    s.parentNode.insertBefore(b, s);})(window.lintrk);
                `, 'marketing-script-2'
            )
        }

    }, [cookieConsentValues.cookieConsent])

    return (
        <CookieConsentContext.Provider value={cookieConsentValues}>
            {children}
        </CookieConsentContext.Provider>
    )
}

export const useCookieConsentContext = (): UseCookieConsentReturnType => {
    const context = useContext(CookieConsentContext)
    if (context === undefined) {
        throw new Error('useCookieConsentContext must be used within a CookieConsentProvider')
    }
    return context
};